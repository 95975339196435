<template>
	<div class="flex">
		<input
			v-model="input"
			:class="[
				'relative w-full px-8 py-2.5 text-sm md:text-base',
				'focus-visible:rounded-sm focus-visible:ring-1 focus-visible:ring-cta',
			]"
			type="text"
			aria-label="Search bar"
			placeholder="Search..."
			@keyup="emit('search', ($event.target as HTMLInputElement).value)"
		/>
		<IconsSearch class="absolute left-1 top-[calc(50%_-_12px)] h-6 w-6" />
		<XMarkIcon
			class="absolute right-2 top-3.5 w-4 cursor-pointer transition duration-200"
			:class="input ? 'opacity-100' : 'opacity-0'"
			@click="
				{
					input = '';
					emit('search', '');
				}
			"
		/>
	</div>
</template>

<script setup lang="ts">
import XMarkIcon from '@heroicons/vue/20/solid/XMarkIcon';

interface Props {
	searchTerm?: string;
}

const props = withDefaults(defineProps<Props>(), {
	searchTerm: '',
});

const emit = defineEmits<{ (eventName: 'search', value: string): void }>();

const input = ref<string>();

watch(
	() => props.searchTerm,
	(newProp) => (input.value = newProp),
);

onMounted(() => {
	if (props.searchTerm) input.value = props.searchTerm;
});
</script>
